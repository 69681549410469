<template>
  <div class="content">
    <div class="m-card">
      <div class="idea_input">
        <Input
          v-model="txtVal"
          type="textarea"
          :autosize="{ minRows: 3, maxRows: 3 }"
          maxlength="200"
          show-word-limit
          placeholder="请输入您的反馈意见"
        />
        <div class="idea_but">
          <Button type="primary" @click="submit">提交</Button>
        </div>
      </div>
      <div class="idea_list">
        <template v-if="list && list.length">
          <div
            class="idea_item"
            :class="{ is_minHei: item.comments && item.comments.length }"
            v-for="(item, index) in list"
            :key="index"
          >
            <div class="idea_left">
              <div class="user_txt">
                <div class="time">{{ item.createAt }}</div>
                <p>{{ item.content }}</p>
              </div>
              <div
                v-if="item.comments && item.comments.length"
                class="service_txt"
              >
                <div class="info">
                  {{ item.comments[0].fullName }}（{{
                    item.comments[0].createAt
                  }}）：
                </div>
                <p>{{ item.comments[0].content }}</p>
              </div>
            </div>
            <img
              v-if="item.comments && item.comments.length"
              class="solve"
              src="@/assets/img/jiejue.png"
            />
          </div>
        </template>
        <div class="no_more mar3" v-if="loading">
          <p>加载中...</p>
        </div>
        <div class="no_more mar3" v-else-if="finished">
          <p>没有更多了</p>
        </div>
        <div
          class="more mar3"
          @click="moreClick"
          v-else-if="list && list.length"
        >
          <p>加载更多</p>
        </div>
        <div class="no_more mar3" v-else>暂无数据！</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
export default {
  name: "layout",
  data() {
    return {
      txtVal: "",
      list: [], //消息列表
      loading: false, //消息列表加载状态
      finished: false, //消息列表是否完成状态
      pageIndex: 1,
      pageSize: 20,
    };
  },
  created() {
    this.getData();
  },
  computed: {
    ...mapGetters({
      exhibitionId: "getExhibitionId",
      exhibitionInfo: "getExhibitionInfo",
      user: "getUser",
    }),
  },
  methods: {
    ...mapActions({
      graphqlPost: "graphqlPostByZXS",
      submitFeedBack: "user/submitFeedBack",
    }),
    async getData() {
      this.loading = true;
      let query = `
        query($query:QueryInput!){
          feedbackQuery{
            query(query:$query){
                hasNextPage
                pageIndex
                totalCount
                items{
                  content 
                  createAt
                  exhibitionId
                  fullName
                  jobTItle
                  origin
                  state
                  title
                  comments(num:1){
                    content
                    createAt
                    fullName
                    id
                    isOfficial
                    replyCount
                 }
                }
              }
            }
        }
      `;
      let where = {
        EqualA: {
          n: "exhibitionId",
          v: this.exhibitionId,
        },
      };
      let order = [
        {
          N: "createAt",
          v: 0,
        },
      ];
      let opt = {
        query: query,
        variables: {
          query: {
            page: this.pageIndex,
            size: this.pageSize,
            where: JSON.stringify(where),
            order: JSON.stringify(order),
          },
        },
      };
      let data = await this.graphqlPost(opt);
      let res = JSON.parse(JSON.stringify(data.data.feedbackQuery.query));
      res.items.forEach((e) => {
        e.createAt = moment(e.createAt).format("YYYY/MM/DD HH:mm:ss");
        if (e.comments && e.comments.length) {
          e.comments.forEach((c) => {
            c.createAt = moment(c.createAt).format("YYYY/MM/DD HH:mm:ss");
          });
        }
      });
      console.log("反馈", res);
      if (this.pageIndex == 1) {
        this.list = res.items;
      } else {
        this.list = [...new Set([...this.list, ...res.items])];
      }
      this.loading = false;
      if (!res.hasNextPage) {
        this.finished = true;
      }
    },
    //点击加载更多
    moreClick() {
      if (this.finished) return;
      this.pageIndex += 1;
      this.getData();
    },
    async submit() {
      if (!this.txtVal) {
        this.$Message.warning("反馈内容不能为空");
        return;
      }
      if (/.*1[3|4|5|7|8][0-9]{9}.*/.test(this.txtVal)) {
        this.$Message.warning("反馈内容不能包含手机号");
        return;
      }
      let opt = {
        exhibitionId: this.exhibitionId,
        origin: this.exhibitionInfo.name,
        userId: this.user.userId,
        memberId: this.user.inMember.memberId,
        content: this.txtVal,
        avatarUrl: this.user.avatarUrl,
      };

      if (this.user.name) {
        opt.fullName = this.user.name;
      }
      if (this.user.company) {
        opt.companyName = this.user.company;
      }
      if (this.user.jobTitle) {
        opt.jobTItle = this.user.jobTitle;
      }
      if (this.user.phone) {
        opt.phoneNumber = this.user.phone;
      }
      if (this.user.title) {
        opt.title = this.user.title;
      }
      let res = await this.submitFeedBack(opt);
      if (res.result) {
        this.txtVal = "";
        this.$Message.success("提交成功");
        this.getData();
      } else {
        this.$Message.error(res.message || "提交失败");
      }
    },
  },
  destroyed() {},
};
</script>

<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.content {
  min-height: 700px;
  .m-card {
    min-height: 663px;
    background: #fff;
    border: solid 1px #ededed;
    padding: 30px 20px;
    .mc-top-title::before {
      opacity: 0;
      font-size: 16px;
    }
    .idea_input {
      ::v-deep .ivu-input-wrapper {
        .ivu-input {
          height: 100px !important;
          min-height: 100px !important;
          max-height: 100px !important;
          padding: 13px;
          border-radius: 4px;
          resize: none;
        }
        .ivu-input-word-count {
          bottom: 15px;
          right: 100px;
        }
      }
      .idea_but {
        text-align: right;
        ::v-deep .ivu-btn {
          margin-top: 20px;
          width: 100px;
          height: 36px;
        }
      }
    }
    .idea_list {
      margin-top: 30px;
      .idea_item {
        &.is_minHei {
          min-height: 128px;
        }
        padding: 20px;
        position: relative;
        background-color: #f7f7f7;
        border-radius: 3px;
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
        .solve {
          display: block;
          width: 87px;
          height: 87px;
          position: absolute;
          right: 20px;
          top: 20px;
        }
        .idea_left {
          max-width: calc(100% - 107px);
          .user_txt {
            .time {
              color: #999;
              margin-bottom: 4px;
            }
            p {
              color: #333;
              font-size: 16px;
              line-height: 1.5;
            }
          }
          .service_txt {
            margin-top: 40px;
            .info {
              color: #999;
              padding: 0 6px;
              background: #e7f4ff;
              height: 20px;
              display: inline-block;
              margin-bottom: 4px;
            }
            .p {
              color: #333;
              line-height: 1.5;
            }
          }
        }
      }
    }
  }
}
</style>